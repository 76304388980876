import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink, from, split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { persistCache } from 'apollo3-cache-persist';
import { createLink } from 'apollo-absinthe-upload-link';
import { RetryLink } from '@apollo/client/link/retry';
import { enqueueSnackbar } from 'notistack';
import { getProgressSubscriber } from './progress';
import getProtocol from './getProtocol.mjs';
import getOrigin from './getOrigin';
import getApiUrl from './getApiUrl';

const merge = {
  merge(existing, incoming, { mergeObjects }) {
    return mergeObjects(existing, incoming);
  },
};

export const cacheOptions = {
  typePolicies: {
    User: {
      keyFields: ['username'],
    },
    Tag: {
      keyFields: ['slug'],
    },
    ProfileCompany: {
      keyFields: false,
    },
    ProfilePage: {
      keyFields: false,
    },
    Profile: {
      fields: {
        page: merge,
        company: merge,
      },
    },
    JobCompany: {
      keyFields: false,
    },
    Job: {
      fields: {
        company: merge,
      },
    },
  },
};

let apolloClient = null;

const graphCMSLink = new HttpLink({
  uri: 'https://api-eu-central-1.graphcms.com/v2/ck17p5hzt00ti01da4bgu326h/master',
});

export const errorHandler = ({ graphQLErrors, networkError, operation }) => {
  if (typeof window !== 'undefined') {
    const { passErrors = [] } = operation.getContext();

    const filteredGraphQLErrors = graphQLErrors
      .filter(({ message }) => !passErrors.includes(message));

    if (filteredGraphQLErrors.length > 0) {
      if (filteredGraphQLErrors[0].message === 'invalid_token') {
        localStorage.clear();

        window.location.reload();

        return;
      }

      const uniqueMessages = new Set(
        filteredGraphQLErrors
          .map((error) => error.message),
      );

      const errorMessage = [...uniqueMessages].join(', ');

      enqueueSnackbar(errorMessage, { variant: 'error' });

      return;
    }

    if (networkError) {
      enqueueSnackbar(
        `Network error: ${networkError.message}`,
        { variant: 'error' },
      );
    }
  }
};

export const errorLink = onError(errorHandler);

function create(initialState, options = {}) {
  const { req: request = {}, reqUrl } = options;
  const { headers: requestHeaders, originalUrl } = request;
  const url = reqUrl || (request.get && `${
    getProtocol(request)}://${request.get('host')}${originalUrl}`);
  const httpLink = createLink({
    uri: getApiUrl(getOrigin(url)),
    getProgressSubscriber,
  });

  const ipAddressMiddleWare = new ApolloLink((operation, forward) => {
    if (requestHeaders) {
      const {
        'x-real-ip': xRealIp,
        'x-forwarded-for': xForwardedFor,
      } = requestHeaders;

      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'x-real-ip': xRealIp,
          'x-forwarded-for': xForwardedFor,
        },
      }));
    }

    return forward(operation);
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const accessToken = typeof localStorage === 'undefined'
      ? null : localStorage.getItem('accessToken');

    const authorization = accessToken ? `Bearer ${accessToken}` : null;
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization,
      },
    }));
    return forward(operation);
  });

  const apiLink = from([
    errorLink,
    new RetryLink(),
    ipAddressMiddleWare,
    authMiddleware,
    httpLink,
  ]);

  const cache = new InMemoryCache(cacheOptions);

  cache.restore({ ...initialState });

  if (typeof localStorage !== 'undefined') {
    persistCache({
      cache,
      storage: localStorage,
    });
  }

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,
    link: split(
      (operation) => operation.getContext().source === 'GraphCMS',
      graphCMSLink,
      apiLink,
    ),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}

export default function initApollo(initialState, options) {
  if (!process.browser) {
    return create(initialState, options);
  }

  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
