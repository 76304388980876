import App from 'next/app';
import React, { useMemo } from 'react';
import { withRouter } from 'next/router';
import { ApolloProvider } from '@apollo/client';
import { RawIntlProvider, createIntl } from 'react-intl';
import Theme from '@fuww/library/src/Theme';
import { NavigationProvider } from '@fuww/library/src/lib/useShowNavigation';
import Container from '@fuww/library/src/Container';
import NotificationsProvider from '../components/NotificationsProvider';
import SiteLayout from '../components/Layout';
import DashboardLayout from '../components/Dashboard/Layout/Dynamic';
import PlausibleScript from '../components/PlausibleScript';
import SiteContext from '../components/SiteContext';
import ErrorBoundary from '../components/ErrorBoundary';
import AccessTokenRefresher from '../components/AccessTokenRefresher';
import initApollo from '../lib/initApollo';
import hasNews from '../lib/hasNews';
import hasLocalNews from '../lib/hasLocalNews';
import isJobsComplete from '../lib/isJobsComplete';
import hasJobLanding from '../lib/hasJobLanding';
import hasJobsInLanding from '../lib/hasJobsInLanding';
import isLite from '../lib/isLite.mjs';
import getPrefix from '../lib/getPrefix.mjs';
import getAdUnitPrefix from '../lib/getAdUnitPrefix';
import getNewsboard from '../lib/getNewsboard';
import getJobsLocale from '../lib/getJobsLocale';
import getOrigin from '../lib/getOrigin';
import getBoard from '../lib/getBoard';
import isProduction from '../lib/isProduction';
import cache from '../lib/intlCache';
import { getMessages } from '../lib/i18n.mjs';
import getAppProperties from '../lib/getAppProperties';
import removeTrailingDot from '../lib/removeTrailingDot';
import getWindowUrl from '../lib/getWindowUrl.mjs';
import correctPrefix from '../lib/correctPrefix';
import redirectAmp from '../lib/redirectAmp';
import getSeed from '../lib/getSeed';
import { SeedContext } from '../lib/useSeed';
import '../lib/reload';
import createAnalyticsEventMutation
from '../lib/mutations/createAnalyticsEvent.graphql';
import isDashboard from '../lib/isDashboard.mjs';
import checkBrowserSupport from '../lib/checkBrowserSupport';
import UserProvider from '../components/UserProvider';
import RedirectMessage from '../components/RedirectMessage';

const cacheControl = 'max-age=600, '
  + 'stale-while-revalidate=3600, stale-if-error=259200';

export async function reportWebVitals({
  name, value,
}) {
  const variables = {
    title: name,
    type: 'TIMING',
    url: getWindowUrl(),
    value,
  };

  const apolloClient = initApollo();
  await apolloClient.mutate({
    mutation: createAnalyticsEventMutation,
    variables,
  });
}

export const Layout = ({ dashboard, Component, pageProperties }) => {
  const getLayout = useMemo(() => Component.getLayout || (dashboard
    ? (page) => (<DashboardLayout>{page}</DashboardLayout>)
    : (page) => (<SiteLayout>{page}</SiteLayout>)),
  [dashboard, Component.getLayout]);

  return getLayout(<Component {...pageProperties} />);
};

class MyApp extends App {
  static async getInitialProps({ Component, ctx: context }) {
    const pageProperties = Component.getInitialProps
      ? await Component.getInitialProps(context)
      : {};

    const { res, req } = context;

    if (!req || req.get) correctPrefix(context);

    redirectAmp(context);

    if (res && res.get && !res.headersSent) {
      if (!res.get('cache-control')) {
        res.setHeader('Cache-Control', cacheControl);
      }

      if (!res.hasHeader('Surrogate-Key') && !res.hasHeader('Cache-Tag')) {
        res.setHeader('Surrogate-Key', 'pwa-ssr');
        res.setHeader('Cache-Tag', 'pwa-ssr');
      }
    }

    const appProperties = getAppProperties(context);

    return {
      ...appProperties,
      pageProps: pageProperties,
      seed: getSeed(),
    };
  }

  componentDidMount() {
    removeTrailingDot();
    checkBrowserSupport();
  }

  render() {
    const {
      Component,
      pageProps: pageProperties = {},
      locale = pageProperties.locale || 'en-IE',
      router: { route },
      seed,
    } = this.props;
    const { apolloClient, apolloState } = pageProperties;
    const url = getWindowUrl() || this.props.url || pageProperties.url;
    const client = apolloClient
      || initApollo(apolloState, { reqUrl: url });
    const prefix = getPrefix(url);
    const dashboard = isDashboard(url);

    const intl = createIntl(
      {
        locale,
        messages: getMessages(locale),
      },
      cache,
    );

    return (
      <ErrorBoundary>
        <SiteContext.Provider value={{
          url,
          prefix,
          locale,
          jobsLocale: getJobsLocale(locale),
          hasLocalNews: hasLocalNews(prefix),
          hasNews: hasNews(prefix),
          lite: isLite(prefix),
          jobsComplete: isJobsComplete(prefix),
          jobsLanding: hasJobLanding(prefix),
          hasJobsInLanding: hasJobsInLanding(prefix),
          adUnitPrefix: getAdUnitPrefix(url),
          production: isProduction(url),
          newsboard: getNewsboard(prefix),
          board: getBoard(prefix),
          origin: getOrigin(url),
          dashboard,
          isCareerPage: route.includes('/careers'),
        }}
        >
          <SeedContext.Provider value={seed}>
            <NavigationProvider>
              <ApolloProvider client={client}>
                <RawIntlProvider
                  value={intl}
                >
                  <UserProvider>
                    <Container maxWidth={false} disableGutters>
                      <NotificationsProvider>
                        <Theme>
                          <AccessTokenRefresher />
                          <RedirectMessage />
                          <Layout
                            dashboard={dashboard}
                            Component={Component}
                            pageProperties={pageProperties}
                          />
                        </Theme>
                      </NotificationsProvider>
                    </Container>
                  </UserProvider>
                </RawIntlProvider>
              </ApolloProvider>
            </NavigationProvider>
          </SeedContext.Provider>
        </SiteContext.Provider>
        <PlausibleScript />
      </ErrorBoundary>
    );
  }
}

export default withRouter(MyApp);
