const resetHash = () => {
  window.history.pushState(
    '',
    document.title,

    window.location.pathname + window.location.search,
  );
};

export default resetHash;
