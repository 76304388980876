import React, { useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import LayoutComponent, {
  layoutPropertyTypes,
  LayoutProperties,
} from '@fuww/library/src/Layout';
import { useIntl } from 'react-intl';
import { enqueueSnackbar } from 'notistack';
import { useRouter } from 'next/router';
import DynamicEmailConfirmationAlert
  from './Users/DynamicEmailConfirmationAlert';
import DynamicNavigation from './DynamicNavigation';
import DynamicFooter from './DynamicFooter';
import hiddenLoading from '../lib/hiddenLoading';
import { UserContext } from './UserProvider';
import messages from '../lib/messages.mjs';
import { Router } from '../routes.mjs';
import { saveRouterState } from '../lib/routerState';

const DynamicGoogleOneTap = dynamic(
  () => import('./LoginSignup/GoogleOneTapProvider'), {
    ssr: false,
    loading: hiddenLoading,
  },
);

const Layout = ({
  isExecutive,
  children,
  ...properties
}: LayoutProperties) => {
  const routerState = useRouter();
  const { route } = routerState;
  const intl = useIntl();
  const { user, loading } = useContext(UserContext);
  const { shouldSetPassword } = user ?? {};

  useEffect(() => {
    if (
      (user && shouldSetPassword)
      && route !== '/set-password'
    ) {
      enqueueSnackbar(
        intl.formatMessage(messages.setYourPassword),
        {
          variant: 'warning',
          preventDuplicate: true,
          persist: true,
          onClose: () => {
            saveRouterState(routerState);
            Router.pushRoute('set-password');
          },
        },
      );
    }
  }, [shouldSetPassword, route, intl, routerState, user]);

  return (
    <LayoutComponent
      navigation={<DynamicNavigation isExecutive={isExecutive ?? false} />}
      footer={<DynamicFooter />}
      sitewideAlert={(<DynamicEmailConfirmationAlert />)}
      isExecutive={isExecutive ?? false}
      enableReactiveNavbar
      {...properties}
    >
      {children}
      {!user && !loading && <DynamicGoogleOneTap />}
    </LayoutComponent>
  );
};

Layout.propTypes = layoutPropertyTypes;

export default Layout;
