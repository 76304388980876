import React from 'react';
import MUIButton, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import colors from '../Theme/colors';

const MdcButton = styled(MUIButton)({
  borderRadius: 4,
  height: 40,
});

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({
    disableElevation = false,
    disableRipple = true,
    variant = 'outlined',
    ...properties
  }, reference) => (
    <MdcButton
      ref={reference}
      disableElevation={disableElevation}
      disableRipple={disableRipple}
      variant={variant}
      {...properties}
    />
  ),
);

interface ColorButtonProperties extends ButtonProps {
  palette?: Record<string, string>;
}

export const ColorButton = styled(Button, {
  shouldForwardProp: (property) => property !== 'palette',
})<ColorButtonProperties>(({ palette }) => ({
  fontWeight: 'bold',
  backgroundColor: palette?.[600],
  '&:hover': {
    backgroundColor: palette?.[700],
    border: 'none',
  },
  color: colors.white,
  border: 'none',
}));

export {
  Button as default,
};

export { default as MUIButton } from '@mui/material/Button';
