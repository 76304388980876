import { useEffect } from 'react';
import { enqueueSnackbar, VariantType } from 'notistack';
import { useRouter } from 'next/router';
import resetHash from '../lib/resetHash';

const messagePattern = /^#(error|success|warning|info)=(.+)$/;

const RedirectMessage = () => {
  // workaround for app shell hash issue
  // this will ensure that the useEffect re-runs
  // when the route changes and the hash is added back
  // see: page/appShell.tsx
  const { query } = useRouter();

  useEffect(() => {
    const [
      ,
      variant,
      message,
    ] = window.location.hash.match(messagePattern) ?? [];

    if (variant && message) {
      enqueueSnackbar(decodeURIComponent(message), {
        variant: variant as VariantType,
      });

      resetHash();
    }
  }, [query]);

  return null;
};

export default RedirectMessage;
